import React from "react"
import { Box, Heading, Text, Grid, Flex, Image } from "@chakra-ui/core"
import SEO from "../components/SEO"
import { RichText } from "prismic-reactjs"
import RecentPosts from "../components/Blog/RecentPosts"
import Categories from "../components/Blog/Categories"
import SharePost from "../components/Blog/SharePost"
import CategorySubLink from "../components/Blog/CategorySubLink"
import PostNavigationItem from "../components/Blog/PostNavigationItem"
import BackLink from "../components/Blog/BackLink"
import BlogDivider from "../components/Blog/BlogDivider"
import PageBanner from "../components/PageBanner"
import Layout from "../components/Layout"
import styled from "@emotion/styled"

const StyledBody = styled(Box)`
  & img {
    max-width: 100%;
  }
  & li {
    margin-top: 0.7em;
    margin-bottom: 0.7em;
  }
  html,
  address,
  blockquote,
  body,
  dd,
  div,
  dl,
  dt,
  fieldset,
  form,
  frame,
  frameset,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  noframes,
  ol,
  p,
  ul,
  center,
  dir,
  hr,
  menu,
  pre {
    display: block;
    unicode-bidi: embed;
  }
  li {
    display: list-item;
  }
  head {
    display: none;
  }
  table {
    display: table;
  }
  tr {
    display: table-row;
  }
  thead {
    display: table-header-group;
  }
  tbody {
    display: table-row-group;
  }
  tfoot {
    display: table-footer-group;
  }
  col {
    display: table-column;
  }
  colgroup {
    display: table-column-group;
  }
  td,
  th {
    display: table-cell;
  }
  caption {
    display: table-caption;
  }
  th {
    font-weight: bolder;
    text-align: center;
  }
  caption {
    text-align: center;
  }
  body {
    margin: 8px;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
  }
  h3 {
    font-size: 1.17em;
    margin: 0.83em 0;
  }
  h4,
  p,
  blockquote,
  ul,
  fieldset,
  form,
  ol,
  dl,
  dir,
  menu {
    margin: 1.12em 0;
  }
  h5 {
    font-size: 0.83em;
    margin: 1.5em 0;
  }
  h6 {
    font-size: 0.75em;
    margin: 1.67em 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong {
    font-weight: bolder;
  }
  blockquote {
    margin-left: 40px;
    margin-right: 40px;
  }
  i,
  cite,
  em,
  var,
  address {
    font-style: italic;
  }
  pre,
  tt,
  code,
  kbd,
  samp {
    font-family: monospace;
  }
  pre {
    white-space: pre;
  }
  button,
  textarea,
  input,
  select {
    display: inline-block;
  }
  big {
    font-size: 1.17em;
  }
  small,
  sub,
  sup {
    font-size: 0.83em;
  }
  sub {
    vertical-align: sub;
  }
  sup {
    vertical-align: super;
  }
  table {
    border-spacing: 2px;
  }
  thead,
  tbody,
  tfoot {
    vertical-align: middle;
  }
  td,
  th,
  tr {
    vertical-align: inherit;
  }
  s,
  strike,
  del {
    text-decoration: line-through;
  }
  hr {
    border: 1px inset;
  }
  ol,
  ul,
  dir,
  menu,
  dd {
    margin-left: 40px;
  }
  ol {
    list-style-type: decimal;
  }
  ol ul,
  ul ol,
  ul ul,
  ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  u,
  ins {
    text-decoration: underline;
  }
  br:before {
    content: "\A";
    white-space: pre-line;
  }
  center {
    text-align: center;
  }
  :link,
  :visited {
    text-decoration: underline;
  }
  :focus {
    outline: thin dotted invert;
  }
  a {
    color: #1b7fe2;
    text-decoration: underline;
  }
`

const Post = ({ pageContext: { post, prevPost, nextPost } }) => {
  return (
    <Box>
      <SEO title={`${post.title} | Blog`} />
      <Layout>
        <PageBanner bannerText={`${post.title} | Blog`} />
        <Box maxW="1100px" mx="auto" mt={3} px={3} mb={10}>
          <BackLink />
          <Grid gap={6} templateColumns={["1fr", "1fr", "1fr", "1fr auto"]}>
            <Box>
              <Box textAlign="center">
                <Image
                  maxW="90%"
                  maxH="500px"
                  mx="auto"
                  my={8}
                  src={post.image.url + "&w=800"}
                  alt={post.image.alt}
                />
              </Box>
              <Heading fontSize="4xl">{post.title}</Heading>
              <Text color="#707070">{post.date} - 4 min read</Text>
              <Flex justify="space-between" mt={1}>
                <Box>
                  {post.categories.map(category => (
                    <CategorySubLink category={category} />
                  ))}
                </Box>
                <SharePost post={post} />
              </Flex>
              <StyledBody>{RichText.render(post.content)}</StyledBody>
            </Box>
            <Box>
              <RecentPosts />
              <Categories />
            </Box>
          </Grid>
          <Box mt={5}>
            <Heading fontSize="2xl" mb={3}>
              More Articles
            </Heading>
            <BlogDivider />
            <Flex mt={4} justify="space-between" wrap="wrap">
              <PostNavigationItem post={prevPost} />
              <PostNavigationItem post={nextPost} />
            </Flex>
          </Box>
        </Box>
      </Layout>
    </Box>
  )
}

export default Post
