import React from "react"
import { Box, Text, Heading, Image, PseudoBox } from "@chakra-ui/core"
import { PostInterface } from "../../types/post"
import BlogLink from "./BlogLink"
import { getPostLink } from "../../utils/post"

interface PostNavigationItemProps {
  post: PostInterface
}

const PostNavigationItem = (props: PostNavigationItemProps): JSX.Element => {
  const { post } = props

  return (
    <Box maxW="450px" mb={4}>
      <Box textAlign="center">
        <BlogLink to={getPostLink(post)}>
          <Box w="100%" h="200px" overflow="hidden">
            <PseudoBox
              transition="all 0.3s"
              _hover={{ transform: "scale(1.2)" }}
            >
              <Image
                w="100%"
                h="100%"
                objectFit="cover"
                transition="transform 0.3s"
                src={post.image.url + "&w=450"}
                alt={post.image.alt}
              />
            </PseudoBox>
          </Box>
        </BlogLink>
      </Box>
      <Box>
        <BlogLink to={getPostLink(post)}>
          <Heading mt={2} fontSize="xl">
            {post.title}
          </Heading>
        </BlogLink>
        <Text color="#707070" mt={1}>
          {post.date} - 4 min read
        </Text>
      </Box>
    </Box>
  )
}

export default PostNavigationItem
